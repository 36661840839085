<template>
<div class="login">
    <span class="title">
        {{selected.title}}
    </span>
    <div class="label">
        <i class="iconfont icon-admin-fill-copy icon"></i>
        <input type="text" class="input" v-model="admin" placeholder="用户名">
    </div>
    <div class="label">
        <i class="iconfont icon-wodemima icon"></i>
        <input type="password" class="input" v-model="password" placeholder="密码">
    </div>
    <div class="label btn" @click="hanlderClick">
        登陆
    </div>
</div>
</template>

<script>
    export default {
        name: "login",
        methods:{
            hanlderClick(){
               if(this.admin == '' || this.password==''){
                   alert('请填写用户名密码')
                   return
               }
               if(this.admin!=this.selected.admin || this.password!=this.selected.password ){
                   alert('用户名密码错误，请重新填写')
                   return;
               }
               let url = this.selected.url
               window.open(url,'_blank')
            }
        },
        computed:{
            selected(){
                let id = this.$route.query.id
                let s ;
                this.adminList.some(x=>{
                    if(x.id == id){
                        s = x
                        return true
                    }
                })
                return s
            },
        },
        data(){
            return {
                admin:'admin',
                password:'',
                adminList:[
                    {
                        title:'浙师大',
                        url:'https://weidian.eapos.cn/plugins/wechat/h5/mall/31/?#/pages/index/index?page_id=82',
                        id:0,
                        admin:'admin',
                        password:'zsd1'

                    },
                    {
                        title:'泗州小学',
                        url:'https://weidian.eapos.cn/plugins/wechat/h5/mall/31/?#/pages/index/index?page_id=92',
                        id:1,
                        admin:'admin',
                        password:'szxx4'
                    },
                    {
                        title:'浙师大幼儿园',
                        url:'https://weidian.eapos.cn/plugins/wechat/h5/mall/31/?#/pages/index/index?page_id=91',
                        id:2,
                        admin:'admin',
                        password:'zsdyey10'
                    },
                    {
                        title:'玉兰幼儿园',
                        url:'https://weidian.eapos.cn/plugins/wechat/h5/mall/31/?#/pages/index/index?page_id=90',
                        id:3,
                        admin:'admin',
                        password:'ylyey11'
                    },
                    {
                        title:'车站路小学',
                        url:'https://weidian.eapos.cn/plugins/wechat/h5/mall/31/?#/pages/index/index?page_id=89',
                        id:4,
                        admin:'admin',
                        password:'czlxx3'
                    },
                    {
                        title:'吴镇小学',
                        url:'https://weidian.eapos.cn/plugins/wechat/h5/mall/31/?#/pages/index/index?page_id=88',
                        id:5,
                        admin:'admin',
                        password:'wzxx5'
                    },
                    {
                        title:'慈山三小',
                        url:'https://weidian.eapos.cn/plugins/wechat/h5/mall/31/?#/pages/index/index?page_id=87',
                        id:6,
                        admin:'admin',
                        password:'cssx8'
                    },
                    {
                        title:'慈山二小',
                        url:'https://weidian.eapos.cn/plugins/wechat/h5/mall/31/?#/pages/index/index?page_id=86',
                        id:7,
                        admin:'admin',
                        password:'csex7'
                    },
                    {
                        title:'慈山一小',
                        url:'https://weidian.eapos.cn/plugins/wechat/h5/mall/31/?#/pages/index/index?page_id=85',
                        id:8,
                        admin:'admin',
                        password:'csyx6'
                    },
                    {
                        title:'杜鹃小学',
                        url:'https://weidian.eapos.cn/plugins/wechat/h5/mall/31/?#/pages/index/index?page_id=84',
                        id:9,
                        admin:'admin',
                        password:'djxx9'
                    },{
                        title:'实验二小',
                        url:'https://weidian.eapos.cn/plugins/wechat/h5/mall/31/?#/pages/index/index?page_id=83',
                        id:10,
                        admin:'admin',
                        password:'syex2'
                    },
                    {
                        title:'实验幼儿园',
                        url:'https://weidian.eapos.cn/plugins/wechat/h5/mall/31/?#/pages/index/index?page_id=93',
                        id:11,
                        admin:'admin',
                        password:'syyeyfs'
                    },
                    {
                        title:'实验小学北校区',
                        url:'https://weidian.eapos.cn/plugins/wechat/h5/mall/31/?#/pages/index/index?page_id=95',
                        id:12,
                        admin:'admin',
                        password:'syxxbxq'
                    },
                    {
                        title:'实验小学南校区',
                        url:'https://weidian.eapos.cn/plugins/wechat/h5/mall/31/?#/pages/index/index?page_id=94',
                        id:13,
                        admin:'admin',
                        password:'syxxnxq'
                    },{
                        title:'华师大二附嘉善学校',
                        url:'https://weidian.eapos.cn/plugins/wechat/h5/mall/31/?#/pages/index/index?page_id=22',
                        id:14,
                        admin:'admin',
                        password:'hsd26'
                    },{
                        title:'惠民小学',
                        url:'https://weidian.eapos.cn/plugins/wechat/h5/mall/31/?#/pages/index/index?page_id=26',
                        id:15,
                        admin:'admin',
                        password:'hmxx25'
                    },{
                        title:'天凝幼儿园',
                        url:'https://weidian.eapos.cn/plugins/wechat/h5/mall/31/?#/pages/index/index?page_id=42',
                        id:16,
                        admin:'admin',
                        password:'tnyey28'
                    },{
                        title:'亭桥小学',
                        url:'https://weidian.eapos.cn/plugins/wechat/h5/mall/31/?#/pages/index/index?page_id=41',
                        id:17,
                        admin:'admin',
                        password:'tqxx27'
                    },{
                        title:'慈山四小',
                        url:'https://weidian.eapos.cn/plugins/wechat/h5/mall/31/?#/pages/index/index?page_id=40',
                        id:18,
                        admin:'admin',
                        password:'cssx24'
                    },{
                        title:'杜鹃幼儿园',
                        url:'https://weidian.eapos.cn/plugins/wechat/h5/mall/31/?#/pages/index/index?page_id=39',
                        id:19,
                        admin:'admin',
                        password:'djyey23'
                    },{
                        title:'城西小学',
                        url:'https://weidian.eapos.cn/plugins/wechat/h5/mall/31/?#/pages/index/index?page_id=38',
                        id:20,
                        admin:'admin',
                        password:'cxxx22'
                    },{
                        title:'洪溪小学',
                        url:'https://weidian.eapos.cn/plugins/wechat/h5/mall/31/?#/pages/index/index?page_id=37',
                        id:21,
                        admin:'admin',
                        password:'hxxx21'
                    },{
                        title:'大通小学',
                        url:'https://weidian.eapos.cn/plugins/wechat/h5/mall/31/?#/pages/index/index?page_id=36',
                        id:22,
                        admin:'admin',
                        password:'dtxx20'
                    },{
                        title:'俞汇小学',
                        url:'https://weidian.eapos.cn/plugins/wechat/h5/mall/31/?#/pages/index/index?page_id=35',
                        id:23,
                        admin:'admin',
                        password:'yhxx19'
                    },{
                        title:'逸夫小学',
                        url:'https://weidian.eapos.cn/plugins/wechat/h5/mall/31/?#/pages/index/index?page_id=34',
                        id:24,
                        admin:'admin',
                        password:'yfxx18'
                    },{
                        title:'四洲中学',
                        url:'https://www.51hdb.com/plugins/wechat/h5/mall/31/?#/pages/index/index?page_id=33',
                        id:25,
                        admin:'admin',
                        password:'szzx14'
                    },{
                        title:'陶庄小学',
                        url:'https://weidian.eapos.cn/plugins/wechat/h5/mall/31/?#/pages/index/index?page_id=32',
                        id:26,
                        admin:'admin',
                        password:'tzzx16'
                    },{
                        title:'大云学校',
                        url:'https://weidian.eapos.cn/plugins/wechat/h5/mall/31/?#/pages/index/index?page_id=31',
                        id:27,
                        admin:'admin',
                        password:'dyxx15'
                    },{
                        title:'泗洲中学',
                        url:'https://weidian.eapos.cn/plugins/wechat/h5/mall/31/?#/pages/index/index?page_id=30',
                        id:28,
                        admin:'admin',
                        password:'szzx14'
                    },{
                        title:'硕士小学',
                        url:'https://weidian.eapos.cn/plugins/wechat/h5/mall/31/?#/pages/index/index?page_id=27',
                        id:29,
                        admin:'admin',
                        password:'ssxx11'
                    },{
                        title:'实验中学',
                        url:'https://weidian.eapos.cn/plugins/wechat/h5/mall/31/?#/pages/index/index?page_id=29',
                        id:30,
                        admin:'admin',
                        password:'syzx13'
                    },{
                        title:'嘉善四中',
                        url:'https://weidian.eapos.cn/plugins/wechat/h5/mall/31/?#/pages/index/index?page_id=28',
                        id:31,
                        admin:'admin',
                        password:'jssz12'
                    },{
                        title:'上海大学附属嘉善实验学校',
                        url:'https://weidian.eapos.cn/plugins/wechat/h5/mall/31/?#/pages/index/index?page_id=148',
                        id:32,
                        admin:'admin',
                        password:'shjssy33'
                    },{
                        title:'上海理工大学附属嘉善实验学校',
                        url:'https://weidian.eapos.cn/plugins/wechat/h5/mall/31/?#/pages/index/index?page_id=149',
                        id:33,
                        admin:'admin',
                        password:'shlgjssy34'
                    },{
                        title:'里泽中心学校',
                        url:'https://weidian.eapos.cn/plugins/wechat/h5/mall/31/?#/pages/index/index?page_id=339',
                        id:34,
                        admin:'admin',
                        password:'lzxx2'
                    },{
                        title:'上海世外教育附属西塘小学',
                        url:'https://weidian.eapos.cn/plugins/wechat/h5/mall/31/?#/pages/index/index?page_id=338',
                        id:35,
                        admin:'admin',
                        password:'xtxl1'
                    }
                    ]
            }
        },
    }
</script>

<style scoped>
.login{
    width:100%;
    height:100vh;
}
.title{
    display: inline-block;width: 100%;
    text-align: center;
    margin-top: 160px;
    margin-bottom: 10px;
    color:white;
}
.label{
    width:80%;
    height:40px;
    margin: 0 auto;
    border-radius: 40px;
    background-color: white;
    margin-bottom: 10px;
}
    .input{
        outline: none;
        border: none;
        display: inline-block;
        height:100%;
        border-radius: 40px;
        padding: 0 20px;
    }
    .icon{
        margin-left: 10px;
    }
    .btn{
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #37AA53;
        color: white;
    }
</style>
